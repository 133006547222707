import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ActionRejection, Company, CompanyStatusAudit, PayingEntity } from '@grain/admin-console-api-types';
import DetailsCard from '@grain/web-components/details-card/DetailsCard';
import type { DetailsCardValue } from '@grain/web-components/details-card/DetailsCardValues';
import DetailsPage from '../layout/DetailsPage';
import EditConfigDialog from '../config/EditConfigDialog';
import BaseInput from '@grain/web-components/forms/BaseInput';
import './CustomerManagementPage.scss';
import { Dislike, SearchNormal } from 'iconsax-react';
import ActionRejectionsTable from '../action-rejections/ActionRejectionsTable';
import SectionCard from '@grain/web-components/layout/SectionCard';
import colors from '@grain/web-components/styles/colors.scss';
import CustomerStatusBadge from './CustomerStatusBadge';
import CustomerStatusAuditsTable from './CustomerStatusAuditsTable';
import ActionRejectionsService from '../../services/ActionRejectionsService';
import CustomersService from '../../services/CustomersService';

type DetailsDescription<EntityType> = Partial<Record<keyof EntityType, string>>;
type CompanyDetails = Omit<DetailsDescription<Company>, 'payingEntity'> & {
  payingEntity: DetailsDescription<PayingEntity>;
};

const customerDetailsFields: CompanyDetails = {
  externalCustomerId: 'Customer ID',
  country: 'Country',
  taxId: 'Tax ID',
  payingEntity: {
    country: 'Paying Entity Country',
    taxId: 'Paying Entity Tax ID',
    name: 'Paying Entity Name'
  },
  contactEmail: 'Email',
  contactFullName: 'Contact Name',
  contactPhoneNumber: 'Contact Phone Number',
  state: 'State',
  city: 'City',
  address: 'Company Address'
};

function asDetailsCardValue<ET>(entity: ET, fieldMapping: Partial<Record<keyof ET, string>>, field: keyof ET): DetailsCardValue {
  return {
    title: fieldMapping[field]!,
    content: entity?.[field] ? `${entity?.[field]}` : undefined
  };
}

function getCustomerDetailsCardValues(customer: Company | undefined, customerField: keyof Company): DetailsCardValue[] {
  if (!customer) return [];

  if (customerField === 'payingEntity') {
    if (!customer.payingEntity) return [];
    return Object.keys(customerDetailsFields.payingEntity).map((peField) =>
      asDetailsCardValue(customer.payingEntity!, customerDetailsFields.payingEntity, peField as keyof PayingEntity)
    );
  }

  return [asDetailsCardValue<Company>(customer, customerDetailsFields as DetailsDescription<Company>, customerField)];
}

export default function CustomerManagementPage() {
  const [customer, setCustomer] = useState<Company>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingStatusAudits, setLoadingStatusAudits] = useState<boolean>(false);
  const [actionRejections, setActionRejections] = useState<ActionRejection[]>();
  const [statusAudits, setStatusAudits] = useState<CompanyStatusAudit[]>([]);
  const [editedData, setEditedData] = useState<{ manualMarkup?: string }>({});
  const params = useParams();

  const onManualMarkupChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEditedData({ manualMarkup: value });
  };

  const refreshCustomer = useCallback(async () => {
    setLoading(true);
    try {
      const { customerId } = params;
      const [customer, rejections] = await Promise.all([
        CustomersService.getInstance().getCustomerById(customerId!),
        ActionRejectionsService.getInstance().getActionRejectionsRequest({
          customerId
        })
      ]);
      setCustomer(customer);
      setActionRejections(rejections);
    } finally {
      setLoading(false);
    }

    setLoadingStatusAudits(true);
    try {
      const statusAudits = await CustomersService.getInstance().getCustomerStatusAudits(params.customerId!);
      setStatusAudits(statusAudits);
    } finally {
      setLoadingStatusAudits(false);
    }
  }, [params]);

  useEffect(() => {
    (async () => {
      await refreshCustomer();
    })();
  }, [refreshCustomer]);

  const updateManualMarkup = customer ? (
    <div className="manual-markup editable-field">
      <div>{customer.manualMarkup}</div>
      <EditConfigDialog
        onCloseAfterSuccess={refreshCustomer}
        onSubmit={(newValues) => CustomersService.getInstance().updateCustomerManualMarkup(customer.id, newValues.manualMarkup!)}
        editedData={editedData}
        initialData={{ manualMarkup: customer.manualMarkup?.toString() || '' }}
      >
        <BaseInput
          name="manualMarkup"
          labelText="Manual Markup"
          defaultValue={customer.manualMarkup}
          onChange={onManualMarkupChange}
          hideErrorText
          small
        />
      </EditConfigDialog>
    </div>
  ) : null;

  const customerDetails: DetailsCardValue[] = Object.keys(customerDetailsFields).flatMap((field) =>
    getCustomerDetailsCardValues(customer, field as keyof Company)
  );

  if (customer) {
    customerDetails.push({
      title: 'Manual Markup',
      content: updateManualMarkup
    });
  }

  return (
    <DetailsPage className="customers-page-container" title="Manage Customer" loading={loading}>
      <div className="left-pane">
        <DetailsCard
          header={
            <div className="main-details">
              <div className="column main">
                <div className="name">{customer?.name}</div>
                <div className="grain-id">{customer?.id}</div>
              </div>
              {customer && (
                <div className="column">
                  <CustomerStatusBadge customer={customer} />
                </div>
              )}
            </div>
          }
          values={customerDetails}
        />
      </div>
      <div className="right-pane">
        {customer && (
          <SectionCard
            title="Customer Status Audits"
            icon={<SearchNormal color="white" />}
            iconBackgroundColor={colors.purple}
            loading={loadingStatusAudits}
          >
            <CustomerStatusAuditsTable statusAudits={statusAudits} customer={customer!} onStatusChanged={refreshCustomer} />
          </SectionCard>
        )}
        {(actionRejections ?? []).length > 0 && (
          <SectionCard title="Action Rejections" icon={<Dislike color={colors.almostBlack} />} iconBackgroundColor={colors.yellow}>
            <ActionRejectionsTable actionRejections={actionRejections!} />
          </SectionCard>
        )}
      </div>
    </DetailsPage>
  );
}
