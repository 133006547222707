import React, { forwardRef, type HTMLAttributes } from 'react';
import BaseInput from '../forms/BaseInput';
import BaseIcon from '../icons/BaseIcon';
import { CloseCircle, SearchNormal1 } from 'iconsax-react';
import './Searchbar.scss';
import { cn } from '../utils';

export default forwardRef<HTMLDivElement, SearchbarProps>(function Searchbar(props, ref) {
  const { value: searchTerm, placeholder, onChange: handleSearchTermChange, size = 'extraSmall', ...rest } = props;

  return (
    <div ref={ref} {...rest} className={cn('searchbar-container', rest.className)}>
      <BaseInput
        className="searchbar-input"
        startAdornment={
          <div className="searchbar-input-search-icon">
            <BaseIcon size={14} appearance="secondary">
              <SearchNormal1 />
            </BaseIcon>
          </div>
        }
        endAdornment={
          searchTerm && (
            <div onClick={() => handleSearchTermChange?.('')}>
              <BaseIcon size={16} appearance="secondary">
                <CloseCircle />
              </BaseIcon>
            </div>
          )
        }
        value={searchTerm}
        hideErrorText
        {...{ [size]: true }}
        internalTextFieldParams={{ placeholder }}
        onChange={(e) => handleSearchTermChange?.(e.target.value)}
      />
    </div>
  );
});

export interface SearchbarProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  placeholder?: string;
  value?: string;
  onChange?: (searchTerm: string) => void;
  size?: 'extraSmall' | 'small';
}
