import React, { useCallback, useEffect, useState } from 'react';
import { AdminConsoleApiPartner } from '@grain/admin-console-api-types';
import BaseTabs from '@grain/web-components/tabs/BaseTabs';
import Searchbar from '@grain/web-components/searchbar/Searchbar';
import Page from '../layout/Page';
import CreatePartnerDialog from './CreatePartnerDialog';
import PartnersTable from './PartnersTable';
import './PartnersPage.scss';
import PartnersService from '../../services/PartnersService';
import environment from '../../environments/environment';
import { StretchCard } from '@grain/web-components/layout/Card';

const PartnersTableTabs = ['Internal', 'External'];

const INITIAL_PARTNERS_STATE = {
  [PartnersTableTabs[0]]: [],
  [PartnersTableTabs[1]]: []
};

export default function PartnersPage() {
  const [partners, setPartners] = useState<Record<string, AdminConsoleApiPartner[]>>(INITIAL_PARTNERS_STATE);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>(PartnersTableTabs[environment.isProduction ? 1 : 0]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const filterByActiveTab = useCallback(
    (p: AdminConsoleApiPartner, selected: string) => (selected === PartnersTableTabs[0] ? p.internal : !p.internal),
    []
  );

  const fetchPartners = useCallback(
    async (init?: boolean) => {
      try {
        if (init) {
          setIsTableLoading(true);
        }

        const response = await PartnersService.getInstance().getPartners(searchTerm.length > 0 ? { name: searchTerm } : {});
        const partners = response.filter((partner) => !partner.demo);
        setPartners({
          [PartnersTableTabs[0]]: partners.filter((p) => p.internal),
          [PartnersTableTabs[1]]: partners.filter((p) => !p.internal)
        });
      } finally {
        if (init) {
          setIsTableLoading(false);
        }
      }
    },
    [selectedTab, filterByActiveTab, searchTerm]
  );

  useEffect(() => {
    (async function () {
      if (partners === INITIAL_PARTNERS_STATE) {
        await fetchPartners(true);
      } else {
        await fetchPartners(false);
      }
    })();
  }, [searchTerm]);

  return (
    <Page className="partners-page" title="Partners Management" subtitle="Manage Grain's partners">
      <div className="partner-config-row-flex mb-4">
        <Searchbar value={searchTerm} placeholder="Search Partner by name or ID" onChange={setSearchTerm} />
        <CreatePartnerDialog onDialogClose={() => fetchPartners(true)} />
      </div>

      <StretchCard>
        <BaseTabs
          className="mt-0"
          tabs={PartnersTableTabs.map((p) => ({ value: p, title: p }))}
          selectedValue={selectedTab}
          onValueSelected={setSelectedTab}
        />

        <PartnersTable partners={partners[selectedTab]} loading={isTableLoading} />
      </StretchCard>
    </Page>
  );
}
