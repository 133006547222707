import { BookForwardMetaData } from '@grain/core-types';

export enum SessionType {
  Quote = 'Quote',
  Order = 'Order'
}

export interface OrderRequest {
  id: string;
  fromCurrency: string;
  toCurrency: string;
  amount: number;
  endAt: Date;
  fromDelayed: boolean;
  metaData?: BookForwardMetaData;
}
