import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider, useLocation, useSearchParams } from 'react-router-dom';
import AppLayout from '../components/layout/AppLayout';
import environment from '../environments/environment';
import GoogleOAuthorizedPage from '../components/login/GoogleOAuthorizedPage';
import { userSelectors } from '../store/userStore';
import { useSelector } from 'react-redux';
import HedgeManagementPage from '../components/hedges/HedgeManagementPage';
import PartnersPage from '../components/partners/PartnersPage';
import FundTransfersPage from '../components/fund-transfers/FundTransfersPage';
import CustomerManagementPage from '../components/customers/CustomerManagementPage';
import CountryRestrictionsPage from '../components/compliance/CountryRestrictionsPage';
import ActionRejectionsManagementPage from '../components/action-rejections/ActionRejectionsManagementPage';
import UserManagementPage from '../components/users/UserManagementPage';
import ForwardsPage from '../components/partners/risk/ForwardsPage';
import AuditLogsPage from '../components/monitoring/AuditLogsPage';
import SchedulerJobsPage from '../components/tools/SchedulerJobsPage';
import SchedulerJobPage from '../components/tools/SchedulerJobPage';
import PartnerManualHedgingPage from '../components/partners/manual-hedging/PartnerManualHedgingPage';
import SpotsPage from '../components/spots/SpotsPage';
import PartnerUploadTransactionsPage from '../components/partners/transactions/PartnerUploadTransactionsPage';
import { CurrenciesPage } from 'components/currencies/CurrenciesPage';
import { WalletsPage } from 'components/wallets/WalletsPage';
import { PartnerPage } from '../components/partners/PartnerPage';
import { wrapCreateBrowserRouter } from '@sentry/react';

export default function App() {
  const isAuthenticated = useSelector(userSelectors.isAuthenticated);

  const authenticatedRoutes = [
    { path: '/currencies', element: <CurrenciesPage /> },
    { path: '/wallets', element: <WalletsPage /> },
    { path: '/hedges', element: <HedgeManagementPage /> },
    {
      path: '/spots',
      handle: { name: 'Spots' },
      children: [{ index: true, element: <SpotsPage /> }]
    },
    {
      path: '/fund-transfers',
      children: [{ index: true, element: <FundTransfersPage /> }]
    },
    {
      path: '/partners',
      children: [
        { index: true, element: <PartnersPage /> },
        {
          path: ':partnerId',
          children: [
            { index: true, element: <PartnerPage /> },
            {
              path: 'manual-hedging/fromAmount',
              element: <PartnerManualHedgingPage fromAmount />
            },
            { path: 'manual-hedging', element: <PartnerManualHedgingPage /> },
            {
              path: 'upload-transactions',
              element: <PartnerUploadTransactionsPage />
            },
            {
              path: 'customers/:customerId',
              element: <CustomerManagementPage />
            }
          ]
        }
      ]
    },
    { path: '/country-restrictions', element: <CountryRestrictionsPage /> },
    { path: '/action-rejections', element: <ActionRejectionsManagementPage /> },
    { path: '/users', element: <UserManagementPage /> },
    { path: '/forwards', element: <ForwardsPage /> },
    { path: '/audit-logs', element: <AuditLogsPage /> },
    {
      path: '/jobs',
      children: [
        { index: true, element: <SchedulerJobsPage /> },
        {
          path: ':jobId',
          children: [{ index: true, element: <SchedulerJobPage /> }]
        }
      ]
    }
  ];

  const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

  const router = sentryCreateBrowserRouter([
    {
      path: '/',
      element: <AppLayout />,
      children: [
        ...(isAuthenticated ? authenticatedRoutes : []),
        { path: '/google/authorized', element: <GoogleOAuthorizedPage /> },
        { index: true, element: <FallbackNavigate /> }, // For the empty path
        { path: '*', element: <FallbackNavigate /> } // For all unknown paths
      ]
    }
  ]);

  return <RouterProvider router={router} />;
}

function FallbackNavigate() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isAuthenticated = useSelector(userSelectors.isAuthenticated);

  if (!isAuthenticated) {
    window.location.href = `${environment.adminConsoleApiUrl}/auth/google?redirectTo=${window.location.origin}`;
    return null;
  }

  return <Navigate to={{ pathname: '/hedges', search: searchParams.toString() }} state={{ from: location }} replace />;
}
