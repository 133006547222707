import React, { MouseEvent, ReactElement, ReactNode, useEffect, useImperativeHandle, useState } from 'react';
import ReactDOM from 'react-dom';
import './BaseDialog.scss';
import { cn } from '../utils';
import { Tooltip, TooltipContent, TooltipTrigger } from '../tooltip/Tooltip';
import { noop } from 'lodash';

/**
 * @deprecated
 */
const BaseDialog = React.forwardRef(_InnerBaseDialog);

function _InnerBaseDialog(
  {
    actAsScreen,
    trigger,
    children,
    containerClassName,
    contentClassName,
    onOpen,
    externalHeader,
    closeText,
    blur,
    acceptButton,
    cancelButton,
    onAccept,
    onCancel,
    onClose,
    triggerAlt,
    leftHeader,
    open
  }: BaseDialogProps,
  ref: React.ForwardedRef<BaseDialogHandle>
) {
  const [internalOpen, setInternalOpen] = useState<boolean>(open ?? false);

  const isControlled = open !== undefined;

  if (isControlled && open !== internalOpen) {
    setInternalOpen(open);
  }

  useImperativeHandle(ref, () => ({
    openDialog: () => showDialog(),
    hideDialog: () => hideDialog()
  }));

  useEffect(() => {
    if (actAsScreen) {
      setInternalOpen(true);
    }
  }, [actAsScreen]);

  const showDialog = () => {
    setInternalOpen(true);
    onOpen?.();
  };

  const hideDialog = () => {
    setInternalOpen(false);
    onClose?.();
  };

  const onOverlayClick = () => {
    if (!actAsScreen) {
      onCancelClick();
    }
  };

  const onContentClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const onAcceptClick = () => {
    onAccept?.();

    if (!isControlled) {
      hideDialog();
    }
  };

  const onCancelClick = () => {
    onCancel?.();

    if (!isControlled) {
      hideDialog();
    }
  };

  const leftHeaderElement = typeof leftHeader === 'string' ? <div className="left-header-text">{leftHeader}</div> : leftHeader;

  const portal = ReactDOM.createPortal(
    <div
      className={cn('base-dialog-inner-container', {
        open: internalOpen
      })}
      onClick={onOverlayClick}
    >
      <div
        className={cn('base-dialog-overlay', {
          solid: externalHeader,
          blur
        })}
      />
      {externalHeader && (
        <div className="external-header">
          <img alt="logo" className="base-dialog-logo" src="/images/web-components/logo.png" />
          <div className="close-text" onClick={onCancelClick}>
            {closeText}
          </div>
        </div>
      )}
      <div className={cn('base-dialog-content', contentClassName)} onClick={onContentClick}>
        {!externalHeader && (
          <div
            className={cn('inner-header', {
              'with-content': !!leftHeaderElement
            })}
          >
            {leftHeaderElement}
            <div className="close-button" onClick={onCancelClick}>
              ✕
            </div>
          </div>
        )}
        <div className="inner-content">
          {children}
          {(acceptButton || cancelButton) && (
            <div className="buttons-container">
              {acceptButton && React.cloneElement(acceptButton, { onClick: onAcceptClick })}
              {cancelButton && React.cloneElement(cancelButton, { onClick: onCancelClick })}
            </div>
          )}
        </div>
      </div>
    </div>,
    document.body
  );

  return (
    <div
      className={cn('base-dialog-container', containerClassName, {
        open: internalOpen
      })}
    >
      {trigger && (
        <div onClick={(trigger as ReactElement).props.disabled ? noop : showDialog}>
          <Tooltip>
            <TooltipTrigger className={cn({ 'cursor-default': !!triggerAlt })}>{trigger}</TooltipTrigger>
            {triggerAlt && <TooltipContent>{triggerAlt}</TooltipContent>}
          </Tooltip>
        </div>
      )}
      {portal}
    </div>
  );
}

export default BaseDialog;

export interface BaseDialogProps {
  actAsScreen?: boolean;
  trigger?: ReactNode;
  children: ReactNode;
  containerClassName?: string;
  contentClassName?: string;
  onOpen?: () => void;
  externalHeader?: boolean;
  closeText?: string;
  blur?: boolean;
  acceptButton?: React.ReactElement;
  cancelButton?: React.ReactElement;
  onAccept?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  triggerAlt?: string;
  leftHeader?: string | React.ReactElement;
  open?: boolean;
}

export type BaseDialogHandle = {
  openDialog: () => void;
  hideDialog: () => void;
};
