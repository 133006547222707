import React, { ReactNode } from 'react';
import './MessageDialog.scss';
import BaseDialog from '../dialogs/BaseDialog';
import CTAButton, { CTAButtonAppearance } from '../buttons/CTAButton';
import { cn } from '../utils';

/**
 * @deprecated
 */
export default function TwoButtonsDialog(props: TwoButtonsDialogProps) {
  const { trigger, className, title, content, ctaQuestion, onAccept, onCancel, invertButtons, acceptButtonAppearance, triggerAlt, open } =
    props;

  const isControlled = open !== undefined;
  const actAsScreen = !trigger && !isControlled;

  const onCancelClick = () => {
    onCancel?.();
  };

  return (
    <BaseDialog
      {...(isControlled ? { open } : {})}
      actAsScreen={actAsScreen}
      contentClassName={cn('message-dialog-content-container', className)}
      trigger={trigger}
      onAccept={onAccept}
      onCancel={onCancelClick}
      acceptButton={<CTAButton text="Yes" appearance={acceptButtonAppearance || (invertButtons ? 'white' : 'blue')} />}
      cancelButton={<CTAButton text="No" appearance={invertButtons ? 'blue' : 'white'} />}
      triggerAlt={triggerAlt}
    >
      <div className="title">{title}</div>
      {content && <div className="content">{content}</div>}
      {ctaQuestion && <div className="content cta-question">{ctaQuestion}</div>}
    </BaseDialog>
  );
}

interface TwoButtonsDialogProps {
  trigger?: ReactNode;
  open?: boolean;
  className?: string;
  title: string;
  content?: React.ReactNode;
  ctaQuestion?: React.ReactNode;
  onAccept?: () => Promise<string | void> | string | void;
  onCancel?: () => void;
  invertButtons?: boolean;
  acceptButtonAppearance?: CTAButtonAppearance;
  triggerAlt?: string;
}
