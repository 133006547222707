import Page from '../layout/Page';
import React, { useCallback, useEffect, useState } from 'react';
import { ShieldTick } from 'iconsax-react';
import colors from '@grain/web-components/styles/colors.scss';
import SectionCard from '@grain/web-components/layout/SectionCard';
import { GetHedgesRequestQueryParams, Hedge } from '@grain/admin-console-api-types';
import AdminHedgesTable from './AdminHedgesTable';
import HedgesService from '../../services/HedgesService';
import ManagedFiltersForm from '@grain/web-components/filters/ManagedFiltersForm';

export default function HedgeManagementPage() {
  const [hedges, setHedges] = useState<Hedge[]>([]);
  const [totalHedgesCount, setTotalHedgesCount] = useState<number>(0);

  const [hedgesDropdownOptions, setHedgesDropdownOptions] = useState<(Hedge & { partnerName: string })[]>([]);
  const [loadingFiltered, setLoadingFiltered] = useState<boolean>(false);

  const [filtersData, setFiltersData] = useState<GetHedgesRequestQueryParams>({});
  const [page, setPage] = useState<number>(0);

  const fetchHedges = useCallback(
    async (initial?: boolean) => {
      try {
        setLoadingFiltered(true);

        const { hedges, totalCount } = await HedgesService.getInstance().getHedgesRequest({
          ...filtersData,
          page: page.toString()
        });

        setHedges(hedges);
        setTotalHedgesCount(totalCount);

        if (initial) {
          setHedgesDropdownOptions(
            hedges.map((hedge) => ({
              ...hedge,
              partnerName: hedge.company?.organization?.partner?.name || ''
            }))
          );
        }
      } finally {
        setLoadingFiltered(false);
      }
    },
    [page, filtersData]
  );

  useEffect(() => {
    fetchHedges(true);
  }, [page]);

  return (
    <Page className="hedges-management-page-container" title="Hedges Management" subtitle="Manage Grain's counterparty portfolio of hedges">
      <SectionCard title="All Hedges" icon={<ShieldTick color={colors.almostBlack} />} iconBackgroundColor={colors.yellow}>
        <ManagedFiltersForm
          options={hedgesDropdownOptions}
          filtersData={filtersData}
          setFiltersData={setFiltersData}
          onFilterClicked={fetchHedges}
          filters={[
            { label: 'From Currency', key: 'fromCurrency' },
            { label: 'To Currency', key: 'toCurrency' },
            { label: 'Partner Name', key: 'partnerName' }
          ]}
        />
        <AdminHedgesTable hedges={hedges} totalCount={totalHedgesCount} loading={loadingFiltered} page={page} setPage={setPage} />
      </SectionCard>
    </Page>
  );
}
