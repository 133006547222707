import React, { useCallback, useState } from 'react';
import { cn } from '@grain/web-components';
import SectionCard from '@grain/web-components/layout/SectionCard';
import colors from '@grain/web-components/styles/colors.scss';
import { Data } from 'iconsax-react';
import './MapHedgesData.scss';
import { AlertsService } from '@grain/web-utils';
import BaseDataMapper, { MAPPING_TIMEOUT } from '@grain/web-components/mapping/BaseDataMapper';
import { FileMappingRequest } from '@grain/api-utils';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import { MappingHedge } from '@grain/admin-console-api-types';
import PartnersService from '../../../services/PartnersService';
import { FieldsGroup } from '@grain/web-components/mapping/BaseFieldsMapper';
import { notifyError, notifySuccess } from '@grain/web-components/notifications/toasters';

const MAPPING_FORM_ID = 'manual-hedging-mapper';

export default function MapPartnerHedgeData({
  partnerId,
  hedgesFile,
  onParseFailed,
  onMappingComplete,
  hedgesFileUrl,
  fieldGroups
}: MapHedgesDataProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const reportParseError = useCallback(
    (e: Error) => {
      AlertsService.getInstance().captureException(e);
      onParseFailed(e);
    },
    [onParseFailed]
  );

  const reportParseTimeout = useCallback(() => {
    AlertsService.getInstance().captureMessage(`Timeout after trying to parse the file for ${MAPPING_TIMEOUT / 1000} seconds.`, 'warning');
    onParseFailed(new Error('Timeout after attempting to parse file'));
  }, [onParseFailed]);

  const mapFile = async (data: FileMappingRequest<MappingHedge>) => {
    try {
      setLoading(true);
      await PartnersService.getInstance().mapHedgesFile(partnerId, data);
      setLoading(false);
      onMappingComplete();
      notifySuccess('Hedges file is being processed. You will receive a slack message when the hedges completed successfully');
    } catch (e) {
      setLoading(false);
      notifyError('Failed to upload hedges file, please contact dev team for more information.');
      throw e;
    }
  };

  return (
    <SectionCard
      title="Connect your data"
      className={cn('map-hedges-data-container', { loading })}
      icon={<Data />}
      iconBackgroundColor={colors.orange}
      headerRightComponent={<CTAButton text="Done" type="submit" size="small" form={MAPPING_FORM_ID} />}
    >
      <BaseDataMapper<MappingHedge>
        formId={MAPPING_FORM_ID}
        file={hedgesFile}
        onParseFailed={reportParseError}
        onParseTimeout={reportParseTimeout}
        fieldGroups={fieldGroups}
        mapFile={mapFile}
        fileUrl={hedgesFileUrl}
        onMapperLoading={setLoading}
        loading={loading}
      />
    </SectionCard>
  );
}

type MapHedgesDataProps<T = any> = {
  partnerId: string;
  hedgesFileUrl: string;
  hedgesFile: File;
  onParseFailed: (e: Error) => void;
  onMappingComplete: () => void;
  fieldGroups: FieldsGroup<T>[];
};
