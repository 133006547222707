import React, { useCallback, useEffect, useState } from 'react';
import { Company } from '@grain/admin-console-api-types';
import CustomersTable from '../../customers/CustomersTable';
import { getOrganizationTypeText, useOrganizationType } from '../../organizations/getOrganizationType';
import TextButton from '@grain/web-components/buttons/TextButton';
import CustomersService from '../../../services/CustomersService';
import { Card } from '@grain/web-components/layout/Card';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerActions
} from '@grain/web-components/accordion/Accordion';
import { PartnerSectionTitleMarker } from '../partner/PartnerSectionTitleMarker';

export function PartnerCustomersSection(props: PartnerCustomersSectionProps) {
  const { partnerId } = props;
  const [customers, setCustomers] = useState<Company[]>([]);
  const [organizationType, toggleOrganizationType] = useOrganizationType();

  const reloadCustomers = useCallback(async () => {
    try {
      const customers = await CustomersService.getInstance().getCustomersRequest({
        organizationType,
        partnerId
      });
      setCustomers(customers);
    } catch (e) {
      console.error('Failed to load partner customers', e);
    }
  }, [organizationType, partnerId]);

  useEffect(() => {
    (async function () {
      await reloadCustomers();
    })();
  }, [reloadCustomers]);

  return (
    <Card>
      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>
            <span className="font-medium text-secondary flex items-center gap-2">
              <PartnerSectionTitleMarker color="success" />
              {`${organizationType} Customers`}
            </span>

            <AccordionTriggerActions>
              <TextButton
                text={`${getOrganizationTypeText(organizationType)} Customers`}
                color="text-primary"
                size="small"
                onClick={toggleOrganizationType}
              />
            </AccordionTriggerActions>
          </AccordionTrigger>

          <AccordionContent>
            <CustomersTable customers={customers} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
}

type PartnerCustomersSectionProps = {
  partnerId: string;
};
