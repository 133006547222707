import React from 'react';
import './StatusBadge.scss';
import { cn } from '../utils';
import { Link } from 'react-router-dom';

export default function StatusBadge(props: StatusBadgeProps) {
  const { status, text, className, link, icon } = props;

  const statusText = text || status;
  const iconElement = icon ? <div className="icon">{icon}</div> : null;
  const statusElement = (
    <div className="text-status truncate max-w-40" title={statusText}>
      {statusText}
    </div>
  );

  return (
    <div className={cn('status-badge-container', status.replaceAll(' ', '').toLowerCase(), className)}>
      {link ? (
        <Link className="status-badge-link" to={link}>
          {iconElement}
          {statusElement}
        </Link>
      ) : (
        <>
          {iconElement}
          {statusElement}
        </>
      )}
    </div>
  );
}

type StatusBadgeProps = {
  status: string;
  text?: string;
  className?: string;
  link?: string;
  icon?: React.ReactElement;
};
