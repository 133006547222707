import React, { ReactElement, useMemo } from 'react';
import { useGrainCurrencyPairs } from 'hooks';
import { GrainCurrencyPair } from '@grain/core-types';
import './CurrenciesFilters.scss';
import TextButton from '@grain/web-components/buttons/TextButton';
import { CurrencyFilterSelect } from '@grain/web-components/currency-filter-select/CurrencyFilterSelect';
import { FilterRow } from '@grain/web-components/filter-row/FilterRow';
import { GRAIN_CURRENCY_PAIR_FILTERS_DEFAULT_SELECTION } from './CurrenciesPage';

export interface CurrenciesFiltersProps {
  onChange: (filters: Partial<GrainCurrencyPair>) => void;
  dropdownSelection: Partial<GrainCurrencyPair>;
}

export function CurrenciesFilters(props: CurrenciesFiltersProps): ReactElement | null {
  const { onChange, dropdownSelection } = props;
  const { grainCurrencyPairs } = useGrainCurrencyPairs();

  const { fromCurrencies, toCurrencies } = useMemo(
    () =>
      (grainCurrencyPairs || []).reduce(
        (acc, currencyPair) => ({
          fromCurrencies: acc.fromCurrencies.add(currencyPair.fromCurrency),
          toCurrencies: acc.toCurrencies.add(currencyPair.toCurrency)
        }),
        { fromCurrencies: new Set<string>(), toCurrencies: new Set<string>() }
      ),
    [grainCurrencyPairs]
  );

  const handleSelectionChange = (name: string) => (value: string) => {
    const newFilters = { ...dropdownSelection, [name]: value };

    onChange(newFilters);
  };

  const resetSelection = () => {
    onChange(GRAIN_CURRENCY_PAIR_FILTERS_DEFAULT_SELECTION);
  };

  const isPristine = Object.entries(GRAIN_CURRENCY_PAIR_FILTERS_DEFAULT_SELECTION).every(
    ([filterName, filterValue]) =>
      dropdownSelection[filterName as keyof typeof GRAIN_CURRENCY_PAIR_FILTERS_DEFAULT_SELECTION] === filterValue
  );

  return (
    <FilterRow>
      <CurrencyFilterSelect
        currencies={[...fromCurrencies]}
        label="From"
        onValueChange={handleSelectionChange('fromCurrency')}
        sorted
        value={dropdownSelection.fromCurrency}
      />

      <CurrencyFilterSelect
        currencies={[...toCurrencies]}
        label="To"
        onValueChange={handleSelectionChange('toCurrency')}
        sorted
        value={dropdownSelection.toCurrency}
      />

      <TextButton
        className="clear-all-button"
        color="text-primary"
        disabled={isPristine}
        light
        onClick={resetSelection}
        size="small"
        text="Clear All"
      />
    </FilterRow>
  );
}
