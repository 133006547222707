import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  AddGrainCurrencyPairRequestBody,
  GrainCurrencyPairHedgeForwardOrderProvider,
  GrainCurrencyPairHedgeForwardPointProvider,
  GrainCurrencyPairHedgeSpotRateProvider
} from '@grain/admin-console-api-types';
import BaseDialog, { BaseDialogHandle, BaseDialogProps } from '@grain/web-components/dialogs/BaseDialog';
import BaseInput from '@grain/web-components/forms/BaseInput';
import { Separator } from '@grain/web-components/separator/Separator';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import './AddCurrencyPairDialog.scss';
import BaseDropdown from '@grain/web-components/forms/BaseDropdown';
import { useAddGrainCurrencyPair, useEditGrainCurrencyPair } from './hooks';
import BaseAutoComplete from '@grain/web-components/forms/BaseAutoComplete';
import { allCurrencies } from '@grain/rate-utils';
import { HealthStatusChip } from '@grain/web-components/health-status-chip/HealthStatusChip';
import { prettifyProviderName } from './prettify-provider-name';
import { AnimateChangeInHeight } from '@grain/web-components/animate-change-in-height/AnimateChangeInHeight';
import type { GrainCurrencyPairWithProviders } from './types';
import Spinner from '@grain/web-components/spinner/Spinner';

const ADD_CURRENCY_PAIR_FORM_EMPTY_STATE: AddGrainCurrencyPairRequestBody = {
  toCurrency: '',
  fromCurrency: '',
  ticker: '',
  forwardPointsProviders: [],
  spotProviders: [],
  forwardOrderProviders: []
};

const ERRORS_EMPTY_STATE = {
  toCurrency: '',
  fromCurrency: '',
  ticker: '',
  forwardPointsProviders: '',
  spotProviders: '',
  forwardOrderProviders: ''
};

interface Props extends Omit<BaseDialogProps, 'children'> {
  onSubmitCallback: (params: { fromCurrency: string; toCurrency: string }) => void;
  title: string;
  mode: 'add' | 'edit';
  activeCurrencyPair: (GrainCurrencyPairWithProviders & { ticker?: string }) | null;
  enrichingActiveCurrencyPair?: boolean;
}

export default function AddEditCurrencyPairDialog(props: Props) {
  const { mode, activeCurrencyPair, onSubmitCallback, title, enrichingActiveCurrencyPair, onClose, ...restOfProps } = props;
  const [newCurrencyPair, setNewCurrencyPair] = useState<AddGrainCurrencyPairRequestBody>(ADD_CURRENCY_PAIR_FORM_EMPTY_STATE);
  const [errors, setErrors] = useState<Record<keyof AddGrainCurrencyPairRequestBody, string>>(ERRORS_EMPTY_STATE);
  const { addGrainCurrencyPairAsync, isLoadingAddGrainCurrencyPair } = useAddGrainCurrencyPair();
  const { editGrainCurrencyPairAsync, isLoadingEditGrainCurrencyPair } = useEditGrainCurrencyPair();
  const dialogRef = useRef<BaseDialogHandle>(null);

  useEffect(() => {
    setErrors(ERRORS_EMPTY_STATE);
  }, [JSON.stringify(newCurrencyPair)]);

  useEffect(() => {
    if (!activeCurrencyPair) return;
    setNewCurrencyPair({
      fromCurrency: activeCurrencyPair.fromCurrency,
      toCurrency: activeCurrencyPair.toCurrency,
      ticker: activeCurrencyPair.ticker || '',
      forwardPointsProviders: activeCurrencyPair.hedgeForwardPointProviders,
      spotProviders: activeCurrencyPair.hedgeSpotRateProviders,
      forwardOrderProviders: activeCurrencyPair.hedgeForwardOrderProviders
    });
  }, [JSON.stringify(activeCurrencyPair)]);

  const addCTADisabled = isLoadingAddGrainCurrencyPair || !_isValidCurrencyPair(newCurrencyPair);
  const editCTADisabled =
    JSON.stringify(newCurrencyPair) ===
      JSON.stringify({
        fromCurrency: activeCurrencyPair?.fromCurrency,
        toCurrency: activeCurrencyPair?.toCurrency,
        ticker: activeCurrencyPair?.ticker || '',
        forwardPointsProviders: activeCurrencyPair?.hedgeForwardPointProviders,
        spotProviders: activeCurrencyPair?.hedgeSpotRateProviders,
        forwardOrderProviders: activeCurrencyPair?.hedgeForwardOrderProviders
      }) || isLoadingEditGrainCurrencyPair;

  const onSubmit = async () => {
    try {
      setErrors(ERRORS_EMPTY_STATE);
      mode === 'edit'
        ? await editGrainCurrencyPairAsync({ ...newCurrencyPair, id: activeCurrencyPair?.id || '' })
        : await addGrainCurrencyPairAsync(newCurrencyPair);
      onSubmitCallback({
        fromCurrency: newCurrencyPair.fromCurrency,
        toCurrency: newCurrencyPair.toCurrency
      });
      dialogRef?.current?.hideDialog();
    } catch (e) {
      const fieldsErrors = e.extraParams?.fields;
      if (fieldsErrors) {
        const fieldsSplit = fieldsErrors.split(',');
        const errors = fieldsSplit.reduce(
          (acc: Record<keyof AddGrainCurrencyPairRequestBody, string>, field: keyof AddGrainCurrencyPairRequestBody) => {
            acc[field] = e.message;
            return acc;
          },
          {} as Record<keyof AddGrainCurrencyPairRequestBody, string>
        );
        setErrors((prev) => ({ ...prev, ...errors }));
      } else {
        setErrors((prev) => ({ ...prev, fromCurrency: e.message, toCurrency: e.message }));
      }
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let controlledValue = value;
    if (name === 'fromCurrency' || name === 'toCurrency') {
      controlledValue = value
        .replace(/[^a-zA-Z]/g, '')
        .toUpperCase()
        .substring(0, 3);
    }
    setNewCurrencyPair({ ...newCurrencyPair, [name]: controlledValue });
  };

  const onDialogClose = () => {
    setNewCurrencyPair(ADD_CURRENCY_PAIR_FORM_EMPTY_STATE);
    onClose?.();
  };

  return (
    <BaseDialog {...restOfProps} ref={dialogRef} onClose={onDialogClose} contentClassName={`sm:w-2/3 md:w-[600px]`}>
      {enrichingActiveCurrencyPair && (
        <div className="absolute inset-0 bg-white opacity-50 z-10 rounded-3xl flex justify-center items-center">
          <Spinner />
        </div>
      )}
      <div className="w-full h-full overflow-y-auto flex flex-col">
        <div className="title-section-wrapper">
          <p className="text-light-grey text-sm font-medium mr-auto">{title}</p>
        </div>
        <div className="currencies-section-wrapper">
          <Separator className="my-6" />
          <p className="text-secondary text-sm font-medium mr-auto mb-5">Currencies</p>
          <div className="flex flex-col gap-2">
            <div className="input-flex">
              <p className="dialog-side-label">From Currency</p>
              <div className="dialog-input-wrapper">
                <BaseAutoComplete
                  disabled={mode === 'edit'}
                  placeholder="Enter currency code..."
                  name="fromCurrency"
                  value={newCurrencyPair.fromCurrency}
                  freeSolo
                  options={allCurrencies.map((currency) => ({ text: currency, value: currency }))}
                  onValueChange={(name, value) =>
                    onChange({
                      target: {
                        name,
                        value
                      }
                    } as ChangeEvent<HTMLInputElement>)
                  }
                  errorMessage={errors['fromCurrency']}
                  small
                />
              </div>
            </div>
            <div className="input-flex">
              <p className="dialog-side-label">To Currency</p>
              <div className="dialog-input-wrapper">
                <BaseAutoComplete
                  disabled={mode === 'edit'}
                  placeholder="Enter currency code..."
                  name="toCurrency"
                  value={newCurrencyPair.toCurrency}
                  freeSolo
                  options={allCurrencies.map((currency) => ({ text: currency, value: currency }))}
                  onValueChange={(name, value) =>
                    onChange({
                      target: {
                        name,
                        value
                      }
                    } as ChangeEvent<HTMLInputElement>)
                  }
                  errorMessage={errors['toCurrency']}
                  small
                />
              </div>
            </div>
          </div>
        </div>
        <div className="providers-section-wrapper">
          <Separator className="my-6" />
          <p className="text-secondary text-sm font-medium mr-auto mb-5">Providers</p>
          <div className="flex flex-col gap-2">
            <div className="input-flex">
              <p className="dialog-side-label">Spot Providers</p>
              <div className="dialog-input-wrapper">
                <BaseDropdown
                  displayEmpty
                  renderValue={_renderProviderValue}
                  multiple
                  clearableAction={
                    newCurrencyPair.spotProviders.length
                      ? () =>
                          setNewCurrencyPair({
                            ...newCurrencyPair,
                            spotProviders: []
                          })
                      : null
                  }
                  options={Object.values(GrainCurrencyPairHedgeSpotRateProvider).map((val) => ({
                    text: prettifyProviderName(val),
                    value: val
                  }))}
                  name="spotProviders"
                  value={newCurrencyPair.spotProviders}
                  onChange={onChange}
                  errorMessage={errors['spotProviders']}
                  small
                />
              </div>
            </div>
            <AnimateChangeInHeight>
              <div className="input-flex">
                <p className="dialog-side-label">Forward Points Providers</p>
                <div className="dialog-input-wrapper">
                  <BaseDropdown
                    displayEmpty
                    renderValue={_renderProviderValue}
                    multiple
                    clearableAction={
                      newCurrencyPair.forwardPointsProviders.length
                        ? () =>
                            setNewCurrencyPair({
                              ...newCurrencyPair,
                              forwardPointsProviders: []
                            })
                        : null
                    }
                    options={Object.values(GrainCurrencyPairHedgeForwardPointProvider).map((val) => ({
                      text: prettifyProviderName(val),
                      value: val
                    }))}
                    name="forwardPointsProviders"
                    value={newCurrencyPair.forwardPointsProviders}
                    onChange={onChange}
                    errorMessage={errors['forwardPointsProviders']}
                    small
                  />
                  {newCurrencyPair.forwardPointsProviders.includes(GrainCurrencyPairHedgeForwardPointProvider.Bloomberg) && (
                    <BaseInput
                      name="ticker"
                      labelText="Ticker"
                      value={newCurrencyPair.ticker}
                      onChange={onChange}
                      errorMessage={errors['ticker']}
                      small
                      sideLabel
                      placeholder="Optional NDF bloomberg ticker"
                    />
                  )}
                </div>
              </div>
            </AnimateChangeInHeight>
            <div className="input-flex">
              <p className="dialog-side-label">Forward Order Providers</p>
              <div className="dialog-input-wrapper">
                <BaseDropdown
                  displayEmpty
                  renderValue={_renderProviderValue}
                  multiple
                  clearableAction={
                    newCurrencyPair.forwardOrderProviders.length
                      ? () =>
                          setNewCurrencyPair({
                            ...newCurrencyPair,
                            forwardOrderProviders: []
                          })
                      : null
                  }
                  options={Object.values(GrainCurrencyPairHedgeForwardOrderProvider).map((val) => ({
                    text: prettifyProviderName(val),
                    value: val
                  }))}
                  name="forwardOrderProviders"
                  value={newCurrencyPair.forwardOrderProviders}
                  onChange={onChange}
                  errorMessage={errors['forwardOrderProviders']}
                  small
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cta-section-wrapper">
          <Separator className="my-6" />
          <div className="flex items-center">
            <CTAButton
              disabled={mode === 'edit' ? editCTADisabled : addCTADisabled}
              loading={isLoadingAddGrainCurrencyPair || isLoadingEditGrainCurrencyPair}
              size="small"
              onClick={onSubmit}
              text={mode === 'edit' ? 'Save Changes' : 'Add'}
            />
          </div>
        </div>
      </div>
    </BaseDialog>
  );
}

function _renderProviderValue(val: string | string[]) {
  if (!val || !val.length) {
    return <span className="text-sm font-normal text-secondary">Select Provider...</span>;
  }
  if (Array.isArray(val)) {
    return (
      <div className="flex items-center h-full overflow-x-auto">
        {val.map((provider, index) => (
          <React.Fragment key={provider}>
            <HealthStatusChip className="bg-white" status={index === 0 ? 'healthy' : null} text={prettifyProviderName(provider)} />
            {index !== val.length - 1 && <span className="text-light-grey">|</span>}
          </React.Fragment>
        ))}
      </div>
    );
  }
  return <span>{val}</span>;
}

function _isValidCurrencyPair(currencyPair: { fromCurrency: string; toCurrency: string }) {
  const { fromCurrency, toCurrency } = currencyPair;
  const from = fromCurrency || '';
  const to = toCurrency || '';
  return from.length === 3 && to.length === 3;
}
