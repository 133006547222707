import { Company } from '@grain/core-types';
import BaseTable, { BaseTableColumnDef, BaseTableColumnDefTextAlignType } from '@grain/web-components/table/BaseTable';
import React from 'react';
import CustomerStatusBadge from './CustomerStatusBadge';
import { useNavigate } from 'react-router-dom';

export default function CustomersTable({ customers, showOrganizationDetails }: CustomersTableProps) {
  const navigate = useNavigate();

  const columns: BaseTableColumnDef<Company>[] = [
    {
      accessorFn: (customer: Company) => customer.externalCustomerId,
      id: 'externalId',
      header: 'Customer ID'
    },
    {
      accessorFn: (customer: Company) => customer.name,
      id: 'name',
      header: 'Name'
    },
    {
      // @ts-expect-error - moving to React 18
      cell: React.useCallback(({ row: { original: customer } }) => <CustomerStatusBadge customer={customer} />, []),
      id: 'status',
      header: 'Status',
      textAlignType: BaseTableColumnDefTextAlignType.Custom
    },
    ...(showOrganizationDetails
      ? [
          {
            accessorFn: (customer: Company) => customer.organization?.name,
            id: 'organizationName',
            header: 'Organization Name'
          },
          {
            accessorFn: (customer: Company) => customer.organization?.partner?.name,
            id: 'partnerName',
            header: 'Partner Name'
          }
        ]
      : [])
  ];

  return (
    <BaseTable columns={columns} data={customers!} paginated pageSize={5} onRowClick={(customer) => navigate(`customers/${customer.id}`)} />
  );
}

type CustomersTableProps = {
  customers: Company[];
  showOrganizationDetails?: boolean;
  paginatorSiblingElement?: React.ReactElement;
};
