import React from 'react';
import { cn } from '../utils';
import { Tag } from '../Tag/Tag';

export const KeyValuePair = (props: KeyValuePairProps) => {
  const { title, value, className, titleClassName } = props;

  return (
    <div className={cn(className, 'flex flex-col gap-2')}>
      <div className={cn('leading-4 text-secondary font-medium', titleClassName)}>{title}</div>

      {Array.isArray(value) ? (
        <div className="flex flex-row gap-1.5 mt-1">
          {value.map((item, index) => (
            <Tag key={index} value={item} />
          ))}
        </div>
      ) : (
        <div className="text-primary truncate" title={value}>
          {value}
        </div>
      )}
    </div>
  );
};

type KeyValuePairProps = {
  title: string;
  value: string | string[];
  className?: string;
  titleClassName?: string;
};
