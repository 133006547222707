import './FundTransfersTable.scss';
import { FundTransfer, FundTransferType } from '@grain/core-types';
import { createColumnHelper, Table, useControlledPagination, useTable } from '@grain/web-components/table-v2';
import { asAmount, asDateString } from '@grain/web-components/utils/formatting';
import StatusBadge from '@grain/web-components/badges/StatusBadge';
import { capitalizeFirstLetter } from '@grain/formatters';

type FundTransfersTableProps = {
  fundTransfers: FundTransfer[];
  totalFundTransfers: number;
  page: number;
  pageSize: number;
  loading?: boolean;
  onPageChange: (pageIndex: number) => void;
};

export default function FundTransfersTable(props: FundTransfersTableProps) {
  const { fundTransfers, loading, page, pageSize, totalFundTransfers, onPageChange } = props;

  const { paginationProps } = useControlledPagination({
    serverSide: true,
    initialPageSize: pageSize,
    totalItems: totalFundTransfers,
    pageIndex: Number(page) || 0,
    onPageIndexChange: onPageChange
  });

  const { table } = useTable(
    {
      columns: COLUMNS,
      data: fundTransfers
    },
    paginationProps
  );

  return <Table className="fund-transfers-table-container" loading={loading} table={table} />;
}

const columnHelper = createColumnHelper<FundTransfer>();

const COLUMNS = [
  columnHelper.accessor('createdAt', {
    header: 'Created At',
    cell: (fundTransferCell) => asDateString(fundTransferCell.getValue()),
    enableSorting: false
  }),
  columnHelper.accessor('externalId', { header: 'External ID', enableSorting: false }),
  columnHelper.accessor('type', { header: 'Type', enableSorting: false }),
  columnHelper.accessor('partnerName', {
    header: 'Partner Name',
    cell: (info) => (
      <div className="truncate" title={info.getValue()}>
        {info.getValue()}
      </div>
    ),
    enableSorting: false
  }),
  columnHelper.accessor('referenceId', {
    header: 'REF',
    cell: (fundTransferCell) => (isTreasuryOutboundFundTransfer(fundTransferCell.row.original) ? fundTransferCell.getValue() : ''),
    enableSorting: false
  }),
  columnHelper.accessor('amount', {
    header: 'Amount',
    cell: (fundTransferCell) => `${fundTransferCell.row.original.currency} ${asAmount(fundTransferCell.getValue())}`,
    enableSorting: false
  }),
  columnHelper.display({
    header: 'Status',
    cell: (fundTransferCell) => {
      const fundTransfer = fundTransferCell.row.original;
      if (isCollectionFundTransfer(fundTransfer)) {
        return fundTransfer.reconciledAt ? (
          <StatusBadge status={fundTransfer.fromCounterpartyName || 'Reconciled'} />
        ) : (
          <StatusBadge status="Unreconciled" />
        );
      }

      if (isWithdrawalFundTransfer(fundTransfer) || isTreasuryInboundFundTransfer(fundTransfer)) {
        return <StatusBadge className="fund-transfer-status-badge-container" status={capitalizeFirstLetter(fundTransfer.status)} />;
      }

      return <div />;
    },
    enableSorting: false
  })
];

function isCollectionFundTransfer(fundTransfer: FundTransfer) {
  return fundTransfer.type === FundTransferType.Collection;
}

function isWithdrawalFundTransfer(fundTransfer: FundTransfer) {
  return fundTransfer.type === FundTransferType.Withdraw;
}

function isTreasuryInboundFundTransfer(fundTransfer: FundTransfer) {
  return fundTransfer.type === FundTransferType.TreasuryInbound;
}

function isTreasuryOutboundFundTransfer(fundTransfer: FundTransfer) {
  return fundTransfer.type === FundTransferType.TreasuryOutbound;
}
