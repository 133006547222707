import React, { PropsWithChildren, useState } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { ArrowDown2, ArrowUp2 } from 'iconsax-react';
import { Separator } from '../separator/Separator';
import colors from '../styles/colors.scss';
import './ViewMoreSection.scss';

export type ViewMoreSectionProps = PropsWithChildren<{
  title: string;
}>;

export function ViewMoreSection(props: ViewMoreSectionProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Collapsible.Root className="collapsible-root" onOpenChange={setIsOpen} open={isOpen}>
      <Collapsible.Trigger asChild>
        <div className="toggle-area">
          <Separator />
          <div className="toggle-area-center">
            <span className="toggle-area-text">{props.title}</span>
            {isOpen ? <ArrowUp2 color={colors.lightGrey} size={14} /> : <ArrowDown2 color={colors.lightGrey} size={14} />}
          </div>
          <Separator />
        </div>
      </Collapsible.Trigger>
      <Collapsible.Content className="collapsible-content">{props.children}</Collapsible.Content>
    </Collapsible.Root>
  );
}
