import React, { useCallback, useEffect, useState } from 'react';
import { AdminConsoleExtendedJob } from '@grain/job-scheduler-types';
import JobSchedulerService from '../../services/JobSchedulerService';
import { useParams } from 'react-router-dom';
import DetailsPage from '../layout/DetailsPage';
import { asDateString, VALUE_MISSING } from '@grain/web-components/utils/formatting';
import DetailsCard from '@grain/web-components/details-card/DetailsCard';
import ApproveJobAction from './ApproveJobAction';
import { InfoCircle, RepeatCircle } from 'iconsax-react';
import colors from '@grain/web-components/styles/colors.scss';
import SchedulerJobsTable from './SchedulerJobsTable';
import SectionCard from '@grain/web-components/layout/SectionCard';
import './SchedulerJobPage.scss';
import { DetailsCardValue } from '@grain/web-components/details-card/DetailsCardValues';
import { Tooltip, TooltipTrigger, TooltipContent } from '@grain/web-components/tooltip/Tooltip';
import CronParser from 'cronstrue';

export default function SchedulerJobPage() {
  const [job, setJob] = useState<AdminConsoleExtendedJob>();
  const [triggeredJobs, setTriggeredJobs] = useState<AdminConsoleExtendedJob[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const params = useParams();

  const onEnable = async (job: AdminConsoleExtendedJob) => {
    setLoading(true);
    try {
      const returnedJob = await JobSchedulerService.getInstance().enableJob(job.id);
      setJob(returnedJob);
    } finally {
      setLoading(false);
    }
  };

  const onDisable = async (job: AdminConsoleExtendedJob) => {
    setLoading(true);
    try {
      const returnedJob = await JobSchedulerService.getInstance().disableJob(job.id);
      setJob(returnedJob);
    } finally {
      setLoading(false);
    }
  };

  const onExecute = async (job: AdminConsoleExtendedJob) => {
    setLoading(true);
    try {
      await JobSchedulerService.getInstance().executeJob(job.id);
      const returnedTriggeredJobs = await JobSchedulerService.getInstance().getTriggeredJobs(job.id);
      setTriggeredJobs(returnedTriggeredJobs);
    } finally {
      setLoading(false);
    }
  };

  const getJob = useCallback(async () => {
    setLoading(true);
    try {
      const job = await JobSchedulerService.getInstance().getJobById(params.jobId!);
      setJob(job);
      const returnedTriggeredJobs = await JobSchedulerService.getInstance().getTriggeredJobs(job.id);
      setTriggeredJobs(returnedTriggeredJobs);
    } finally {
      setLoading(false);
    }
  }, [params.jobId]);

  useEffect(() => {
    (async function () {
      await getJob();
    })();
  }, [getJob]);

  const isDisabled = job?.disabled === undefined ? false : job?.disabled;

  return (
    <DetailsPage className="scheduler-job-container" title="Job Details" loading={isLoading}>
      {job && (
        <div className="left-pane">
          <DetailsCard
            header={
              <div className="main-details">
                <div className="column main">
                  <div className="name">{job.name}</div>
                  <div className="grain-id">{job.id}</div>
                </div>
              </div>
            }
            values={getJobDetails(job, isDisabled)}
            visibleDetailsAmount={10}
          />
          <ApproveJobAction job={job} action="Execute" onApprovedAction={onExecute} />
          {isDisabled ? (
            <ApproveJobAction job={job} action="Enable" onApprovedAction={onEnable} />
          ) : (
            <ApproveJobAction job={job} action="Disable" onApprovedAction={onDisable} />
          )}
        </div>
      )}
      <div className="right-pane">
        <SectionCard
          title="Manually Triggered Jobs (Execute)"
          icon={<RepeatCircle />}
          iconBackgroundColor={colors.yellow}
          loading={isLoading}
        >
          {job && <SchedulerJobsTable jobs={triggeredJobs} />}
        </SectionCard>
      </div>
    </DetailsPage>
  );
}

function getJobDetails(job: AdminConsoleExtendedJob, isDisabled: boolean): DetailsCardValue[] {
  return [
    { title: 'Description', content: job.description },
    {
      title: 'Repeat Interval',
      content: (
        <Tooltip>
          <TooltipTrigger>
            {job.repeatInterval ? (
              <div className="flex items-center gap-2">
                {job.repeatInterval} <InfoCircle color={colors.cta} size={12} />
              </div>
            ) : (
              '-'
            )}
          </TooltipTrigger>

          {job.repeatInterval && <TooltipContent>{parseRepeatInterval(job.repeatInterval)}</TooltipContent>}
        </Tooltip>
      )
    },
    { title: 'Run At', content: asDateString(job?.lastRunAt, true) },
    { title: 'Finished At', content: asDateString(job?.lastFinishedAt, true) },
    { title: 'Failed At', content: asDateString(job?.failedAt, true) },
    { title: 'Fail Reason', content: job?.failReason || VALUE_MISSING },
    { title: 'Fail Count', content: job?.failCount },
    { title: 'Last Modified By', content: job?.lastModifiedBy },
    { title: 'Priority', content: job?.priority },
    { title: 'Start Date', content: asDateString(job?.startDate, true) },
    { title: 'Type', content: job?.type },
    { title: 'Disabled', content: isDisabled.toString() }
  ];
}

const parseRepeatInterval = (repeatInterval: string) => {
  try {
    return CronParser.toString(repeatInterval);
  } catch (error) {
    return repeatInterval;
  }
};
