import React from 'react';
import './BaseTableHeader.scss';
import { cn } from '../utils';
import { flexRender, Header } from '@tanstack/react-table';
import { ArrowDown, ArrowUp, InfoCircle } from 'iconsax-react';
import { BaseTableCellCommonProps } from './BaseTableCell';
import colors from '../styles/colors.scss';
import { BaseTableColumnDef } from './BaseTable';
import Tooltip from '@mui/material/Tooltip';

export default function BaseTableHeader<T>(props: BaseTableHeaderProps<T>) {
  const { header, className, textAlign, width, disableSorting, borderless } = props;

  // undefined on enableSorting means we use the default sortingFn
  const sortingDisabled = disableSorting || header.column.columnDef.enableSorting === false;
  const columnDef = header.column.columnDef as BaseTableColumnDef<T>;

  return (
    <th
      colSpan={header.colSpan}
      className={cn('base-table-header-container', className, {
        sortable: !sortingDisabled,
        borderless
      })}
      onClick={sortingDisabled ? undefined : () => header.column.toggleSorting(header.column.getIsSorted() === 'asc')}
      style={{ width }}
    >
      <div className={cn('header-cell', textAlign)}>
        {flexRender(columnDef.header, header.getContext())}
        {columnDef.info && (
          <Tooltip placement="top" title={columnDef.info} arrow>
            <div className="info-container">
              <InfoCircle size={14} color={colors.cta} />
            </div>
          </Tooltip>
        )}
        {{ asc: <ArrowUp size={16} />, desc: <ArrowDown size={16} /> }[header.column.getIsSorted() as string] ?? null}
      </div>
    </th>
  );
}

interface BaseTableHeaderProps<T> extends BaseTableCellCommonProps {
  header: Header<T, any>;
  disableSorting?: boolean;
  borderless?: boolean;
}
