import CTAButton from '../buttons/CTAButton';
import TextButton from '../buttons/TextButton';
import React, { FormEvent, useState } from 'react';
import './FiltersForm.scss';
import { FilterSearch } from 'iconsax-react';

export default function FiltersForm(props: FiltersFormProps) {
  const { children, onFilter, filterDisabled, onReset, resetDisabled } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const form = React.useRef<HTMLFormElement>(null);

  const filter = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      await onFilter();
    } finally {
      setLoading(false);
    }
  };

  const reset = async () => {
    try {
      setLoading(true);
      await onReset();
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="filter-form-container" onSubmit={filter} ref={form}>
      <div className="children-container">{children}</div>
      <div className="buttons-container">
        <CTAButton
          text="Filter"
          leadingIcon={<FilterSearch />}
          size="small"
          type="submit"
          loading={loading}
          disabled={filterDisabled}
          appearance="white"
        />
        <TextButton text="Clear All" onClick={reset} color="text-primary" disabled={resetDisabled} size="small" />
      </div>
    </form>
  );
}

export type FiltersFormProps = {
  children: React.ReactNode;
  onFilter: () => Promise<void>;
  filterDisabled?: boolean;
  onReset: () => Promise<void>;
  resetDisabled: boolean;
};
