import environment from '../environments/environment';
import { notifyError } from '@grain/web-components/notifications/toasters';
import UsersService from './UsersService';
import ToolsService from './ToolsService';
import RiskService from './RiskService';
import PlatformsService from './PlatformsService';
import PaymentBeneficiariesService from './PaymentBeneficiaries';
import PartnersService from './PartnersService';
import HedgesService from './HedgesService';
import GrainSettingsService from './GrainSettingsService';
import FundTransfersService from './FundTransfersService';
import ForwardsService from './ForwardsService';
import CustomersService from './CustomersService';
import CountryConfigService from './CountryConfigService';
import ActionRejectionsService from './ActionRejectionsService';
import AuditLogsService from './AuditLogsService';
import { AlertsService, AuthenticationService } from '@grain/web-utils';
import JobSchedulerService from './JobSchedulerService';
import { TreasuryService } from './TreasuryService';
import SpotsService from './SpotsService';
import { HttpError, RequestCancelledError } from '@grain/api-utils';

const handleError = (err: Error) => {
  if (!(err instanceof RequestCancelledError)) {
    notifyError(err.message);
  }

  if (((err as HttpError).status ?? 0) >= 500) {
    AlertsService.getInstance().captureException(err);
  }
};

export function initializeService() {
  [
    UsersService,
    ToolsService,
    RiskService,
    PlatformsService,
    PaymentBeneficiariesService,
    PartnersService,
    HedgesService,
    SpotsService,
    GrainSettingsService,
    FundTransfersService,
    ForwardsService,
    CustomersService,
    CountryConfigService,
    AuthenticationService,
    ActionRejectionsService,
    AuditLogsService,
    JobSchedulerService,
    TreasuryService
  ].forEach((service) => {
    service.initialize(environment.adminConsoleApiUrl, handleError);
  });
}
