import Page from '../layout/Page';
import './FundTransfersPage.scss';
import FundTransfersTable from './FundTransfersTable';
import { StretchCard } from '@grain/web-components/layout/Card';
import { useFundTransfers } from './hooks/useFundTransfers';
import { useEffect, useState } from 'react';
import { IconicButton } from '@grain/web-components/iconic-button/IconicButton';
import { usePersistFundTransfers } from './hooks/usePersistFundTransfers';
import { Refresh } from 'iconsax-react';
import Searchbar from '@grain/web-components/searchbar/Searchbar';
import { FundTransferType } from '@grain/core-types';
import { FilterSelect } from '@grain/web-components/filter-select/FilterSelect';
import { capitalizeFirstLetter } from '@grain/formatters';
import TextButton from '@grain/web-components/buttons/TextButton';
import { SeparatorRow } from '@grain/web-components/separator-row/SeparatorRow';

const PER_PAGE = 20;

const INITIAL_FILTERS: FundTransfersFilters = {
  type: 'All',
  searchTerm: ''
};

export default function FundTransfersPage() {
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<FundTransfersFilters>(INITIAL_FILTERS);
  const { fundTransfers, totalFundTransfers, isLoadingFundTransfers } = useFundTransfers({
    page,
    perPage: PER_PAGE,
    searchTerm: filters.searchTerm,
    type: filters.type === 'All' ? undefined : filters.type
  });
  const { persistFundTransfers } = usePersistFundTransfers();

  const handleFilterChange = (name: keyof FundTransfersFilters, value: FundTransfersFilters[keyof FundTransfersFilters]) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFiltersReset = () => {
    setFilters(INITIAL_FILTERS);
  };

  useEffect(() => {
    setPage(0);
  }, [filters]);

  return (
    <Page className="fund-transfers-page-container" title="TransferMate Transfers" subtitle="View all TransferMate transfers">
      <div className="mb-5 flex">
        <SeparatorRow separatorHeight="0.75rem">
          <FilterSelect
            selectValueClassName="text-sm text-primary"
            label="Type:"
            options={[{ value: 'All', text: 'All' }, ...FUND_TRANSFER_TYPES]}
            value={filters.type}
            onValueChange={(value) => handleFilterChange('type', value)}
          />

          <TextButton
            color="text-primary"
            disabled={filters.type === 'All'}
            light
            onClick={handleFiltersReset}
            size="small"
            text="Clear All"
          />
        </SeparatorRow>

        <div className="ml-auto flex gap-4">
          <Searchbar
            placeholder="Search Partner or External ID"
            value={filters.searchTerm}
            onChange={(value) => handleFilterChange('searchTerm', value)}
          />

          <IconicButton className="mr-2.5" text="Persist" icon={Refresh} onClick={persistFundTransfers} />
        </div>
      </div>

      <StretchCard>
        <FundTransfersTable
          loading={isLoadingFundTransfers}
          fundTransfers={fundTransfers || []}
          totalFundTransfers={totalFundTransfers || 0}
          page={page}
          pageSize={PER_PAGE}
          onPageChange={setPage}
        />
      </StretchCard>
    </Page>
  );
}

const FUND_TRANSFER_TYPES = Object.keys(FundTransferType)
  .filter((key) => !key.toLowerCase().includes('internal') && !key.toLowerCase().includes('settlement'))
  .map((key) => ({
    value: FundTransferType[key as keyof typeof FundTransferType],
    text: prettifyFundTransferType(FundTransferType[key as keyof typeof FundTransferType])
  }));

function prettifyFundTransferType(type: FundTransferType) {
  return type
    .replace(/-/g, ' ')
    .split(' ')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ');
}

type FundTransfersFilters = {
  type?: FundTransferType | 'All';
  searchTerm?: string;
};
