import React from 'react';
import SectionCard from '../layout/SectionCard';
import { ExportCurve } from 'iconsax-react';
import colors from '../styles/colors.scss';
import { FileDropzone, FileDropzoneProps } from '../file-dropzone/FileDropzone';

export default function UploadSection<T>(props: UploadSectionProps<T>) {
  return (
    <SectionCard
      title="Upload Your File"
      className="upload-section-container flex-1"
      icon={<ExportCurve color={colors.almostBlack} />}
      iconBackgroundColor={colors.yellow}
    >
      <FileDropzone {...props} />
    </SectionCard>
  );
}

export type UploadSectionProps<T> = FileDropzoneProps<T>;
