import {
  Company,
  CompanyStatusAudit,
  CustomerResponseBody,
  CustomerStatusAuditsResponseBody,
  GetCustomersRequestQueryParams,
} from '@grain/admin-console-api-types';
import { BaseService } from '@grain/web-utils';

export default class CustomersService extends BaseService {
  private static instance: CustomersService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new CustomersService(baseUrl, handleError);
  }

  public static getInstance(): CustomersService {
    return this.instance;
  }

  async getCustomersRequest({ organizationType, partnerId }: GetCustomersRequestQueryParams): Promise<Company[]> {
    const res: CustomersResponse = await this.authenticatedCall({
      url: 'customers/list',
      params: { partnerId, organizationType },
      method: 'GET'
    });

    return res.customers;
  }

  async getCustomerById(id: string): Promise<Company> {
    const { customer }: CustomerResponseBody = await this.authenticatedCall({
      url: `customers/${id}`,
      method: 'GET'
    });
    return customer;
  }

  async getCustomerStatusAudits(id: string): Promise<CompanyStatusAudit[]> {
    const { statusAudits }: CustomerStatusAuditsResponseBody = await this.authenticatedCall({
      url: `customers/${id}/status-audits`,
      method: 'GET'
    });
    return statusAudits;
  }

  async changeCustomerStatus(id: string, newStatus: string): Promise<void> {
    await this.authenticatedCall({
      url: `customers/${id}/update-status`,
      method: 'PUT',
      data: { newStatus }
    });
  }

  async updateCustomerManualMarkup(id: string, manualMarkup: string): Promise<void> {
    await this.authenticatedCall({
      url: `customers/${id}/update-manual-markup`,
      method: 'PUT',
      data: { manualMarkup }
    });
  }
}

export interface CustomersResponse {
  customers: Company[];
}
