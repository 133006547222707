import React, { useEffect, useState } from 'react';
import { Pointer } from 'iconsax-react';
import './SpotsPage.scss';
import colors from '@grain/web-components/styles/colors.scss';
import SpotsTable from './SpotsTable';
import SectionCard from '@grain/web-components/layout/SectionCard';
import SpotsService from '../../services/SpotsService';
import Page from '../layout/Page';
import { TableEmptyState } from '@grain/web-components/empty-states/TableEmptyState';

export default function SpotsPage() {
  const [spots, setSpots] = useState<any[]>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const spots = await SpotsService.getInstance().getSpotsRequest();
        setSpots(spots);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const showEmptyState = spots?.length === 0;
  const emptyState = (
    <div className="spots-page-empty-state">
      <TableEmptyState />
    </div>
  );

  return (
    <Page className="spots-page-container" title="Spots" subtitle="View all spot requests">
      <SectionCard
        title="All Spots"
        className="spot-section-card"
        icon={<Pointer color={colors.almostBlack} />}
        iconBackgroundColor={colors.yellow}
        loading={loading}
      >
        {showEmptyState ? emptyState : <SpotsTable spots={spots || []} paginated showTabs sortable />}
      </SectionCard>
    </Page>
  );
}
