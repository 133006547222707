import React from 'react';
import { AdminConsoleApiPartner } from '@grain/admin-console-api-types';
import PartnerOrganizationsTable from './PartnerOrganizationsTable';
import './PartnerOrganizationsSection.scss';
import { PartnerSectionTitleMarker } from '../partner/PartnerSectionTitleMarker';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@grain/web-components/accordion/Accordion';
import { Card } from '@grain/web-components/layout/Card';

export function PartnerOrganizationsSection(props: PartnerOrganizationsSectionProps) {
  return (
    <Card>
      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>
            <span className="font-medium text-secondary flex items-center gap-2">
              <PartnerSectionTitleMarker color="cta" />
              Organizations
            </span>
          </AccordionTrigger>

          <AccordionContent>
            <PartnerOrganizationsTable organizations={props.partner.organizations ?? []} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
}

type PartnerOrganizationsSectionProps = {
  partner: AdminConsoleApiPartner;
};
