import { HedgeSettlementType } from '@grain/core-types';
import { capitalizeFirstLetter } from '@grain/formatters';
import { BaseDropdownOption } from '@grain/web-components/forms/BaseDropdown';

export function getDisplaySettlementType(type: HedgeSettlementType): string {
  switch (type) {
    case HedgeSettlementType.Collection:
    case HedgeSettlementType.BulkFull:
      return 'Full';
    case HedgeSettlementType.Cycle:
    case HedgeSettlementType.Manual:
    case HedgeSettlementType.Offset:
      return capitalizeFirstLetter(type);
    default:
      throw new Error('Unsupported settlement type');
  }
}

export function getFilteredSettlementTypeOptions(): BaseDropdownOption[] {
  return Object.entries(HedgeSettlementType)
    .filter(([_key, value]) => value !== HedgeSettlementType.Cycle && value !== HedgeSettlementType.BulkFull)
    .map(([_, value]) => ({ text: getDisplaySettlementType(value), value }));
}
