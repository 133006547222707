import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Page from '../layout/Page';
import SectionCard from '@grain/web-components/layout/SectionCard';
import { BookSquare } from 'iconsax-react';
import colors from '@grain/web-components/styles/colors.scss';
import { AdminConsoleApiAuditLog, OrganizationType } from '@grain/audit-logs-service-types';
import AuditLogsService, { GetAuditLogRequestParams } from '../../services/AuditLogsService';
import AuditLogsTable from './AuditLogsTable';
import PartnersService from '../../services/PartnersService';
import BaseAutoComplete from '@grain/web-components/forms/BaseAutoComplete';
import FiltersForm from '@grain/web-components/filters/FiltersForm';
import './AuditLogsPage.scss';

type OrganizationsOption = { text: string; value: string };

const buildFullAuditLog = (
  log: AdminConsoleApiAuditLog,
  liveOrganizations: {
    [key: string]: OrganizationsOption;
  }
) => ({
  ...log,
  ...(log.organizationId ? { organizationName: liveOrganizations[log.organizationId]?.text } : {})
});

export default function AuditLogsPage() {
  const [auditLogs, setAuditLogs] = useState<AdminConsoleApiAuditLog[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [loadingFiltered, setLoadingFiltered] = useState<boolean>(false);
  const [liveOrganizations, setLiveOrganizations] = useState<OrganizationsOption[]>([]);
  const [filtersData, setFiltersData] = useState<GetAuditLogRequestParams>({});

  const auditLogsWithOrganization = useMemo(() => {
    if (liveOrganizations.length && auditLogs.length) {
      const organizationsMap = Object.fromEntries(Object.entries(liveOrganizations).map(([, val]) => [val.value, val]));
      return auditLogs.map((log) => buildFullAuditLog(log, organizationsMap));
    }
    return auditLogs;
  }, [auditLogs, liveOrganizations]);

  const getAuditLogs = useCallback(async () => {
    setLoading(true);
    try {
      const auditLogs = await AuditLogsService.getInstance().getAllAuditLogs();
      setAuditLogs(auditLogs);
    } finally {
      setLoading(false);
    }
  }, []);

  const getOrganizationList = useCallback(async () => {
    const partners = await PartnersService.getInstance().getPartners({
      withOrganizations: true
    });
    const organizationOptions = partners.flatMap((partner) => {
      if (!partner.demo) {
        const liveOrgId = partner.organizations?.find((o) => o.type === OrganizationType.Live)?.id;
        return liveOrgId ? { text: partner.name, value: liveOrgId } : [];
      }

      return [];
    });
    setLiveOrganizations(organizationOptions);
  }, []);

  const reset = async () => {
    await getAuditLogs();
    setFiltersData({});
  };

  const onChange = (dropDownName: string, dropDownNameSelectedValue: string) => {
    setFiltersData({
      ...filtersData,
      [dropDownName]: dropDownNameSelectedValue
    });
  };

  const filter = async () => {
    try {
      setLoadingFiltered(true);
      const auditLogs = await AuditLogsService.getInstance().getAllAuditLogs(filtersData);
      setAuditLogs(auditLogs);
    } finally {
      setLoadingFiltered(false);
    }
  };

  useEffect(() => {
    (async function () {
      await getOrganizationList();
      await getAuditLogs();
    })();
  }, [getAuditLogs, getOrganizationList]);

  const resetDisabled = Object.keys(filtersData).length === 0;

  return (
    <Page className="audit-logs-page" title="Audit Logs" subtitle="Application Audit Logs">
      <SectionCard
        className="audit-logs-table-section"
        title="Audit Logs"
        icon={<BookSquare />}
        iconBackgroundColor={colors.yellow}
        loading={isLoading}
      >
        <FiltersForm onFilter={filter} onReset={reset} resetDisabled={resetDisabled}>
          <BaseAutoComplete
            labelText="Organization"
            name="organizationId"
            value={filtersData?.organizationId}
            options={liveOrganizations}
            onValueChange={onChange}
            hideErrorText
            small
          />
        </FiltersForm>
        <AuditLogsTable auditLogs={auditLogsWithOrganization} loading={loadingFiltered} />
      </SectionCard>
    </Page>
  );
}
