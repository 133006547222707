import React, { useEffect } from 'react';
import Spinner from '@grain/web-components/spinner/Spinner';
import { finishGoogleLogin } from '../../store/userStore';
import { useAppDispatch } from '../../store/store';
import { useNavigate } from 'react-router-dom';

export default function GoogleOAuthorizedPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const querySearchParams = new URLSearchParams(window.location.search);

    // we need to check if the user has the required params in order to finish the login
    const stateParam = querySearchParams.get('state');
    const codeParam = querySearchParams.get('code');
    if (!codeParam && !stateParam) {
      navigate('/');
      return;
    }

    dispatch(finishGoogleLogin({ query: querySearchParams }));
  }, [dispatch, navigate]);

  return <Spinner />;
}
