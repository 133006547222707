import { useMutation, useQueryClient } from '@tanstack/react-query';
import FundTransfersService from '../../../services/FundTransfersService';
import { notifySuccess } from '@grain/web-components/notifications/toasters';
import { queries } from 'queries';

export function usePersistFundTransfers() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: () => {
      return FundTransfersService.getInstance().persistFundTransfers();
    },
    onSuccess: async () => {
      notifySuccess('Fund transfer will be persisted shortly in the background.');
      await queryClient.invalidateQueries({ queryKey: queries.fundTransfers.list._def });
    }
  });

  return {
    persistFundTransfers: mutateAsync
  };
}
