import { GrainCurrencyPairHedgeForwardPointProvider, GrainCurrencyPairHedgeSpotRateProvider } from './currency-pair-status';

export interface IRatesSnapshot {
  revision: number;
  rates: Rates<Tenor>;
  validUntil: Date;
}

type OfflineCurrencyPair = string;

export enum Tenor {
  Spot = '0',
  Week = '7',
  TwoWeeks = '14',
  Month = '30',
  TwoMonths = '60',
  ThreeMonths = '90',
  FourMonths = '120',
  FiveMonths = '150',
  SixMonths = '180',
  SevenMonths = '210',
  EightMonths = '240',
  NineMonths = '270',
  TenMonths = '300',
  ElevenMonths = '330',
  OneYear = '360',
  FifteenMonths = '450',
  EighteenMonths = '540',
  TwentyOneMonths = '630',
  TwoYears = '720'
}

export type RateComponentsObj = {
  rate: number;
  spotRate: number;
  spotProvider: GrainCurrencyPairHedgeSpotRateProvider;
  forwardPoints: number;
  forwardPointsProvider: GrainCurrencyPairHedgeForwardPointProvider;
  riskFactor: number;
  riskLevel: RiskLevelEnum;
};
export type TenorRate<T extends Tenor> = Record<T, RateComponentsObj>;
export type Rates<T extends Tenor> = Record<OfflineCurrencyPair, TenorRate<T>>;
export type TenorMarkup<T extends Tenor> = Record<T, number>;
export type Markups<T extends Tenor> = Record<OfflineCurrencyPair, TenorMarkup<T>>;

export const oneYearTenors = [
  Tenor.Week,
  Tenor.TwoWeeks,
  Tenor.Month,
  Tenor.TwoMonths,
  Tenor.ThreeMonths,
  Tenor.FourMonths,
  Tenor.FiveMonths,
  Tenor.SixMonths,
  Tenor.SevenMonths,
  Tenor.EightMonths,
  Tenor.NineMonths,
  Tenor.TenMonths,
  Tenor.ElevenMonths,
  Tenor.OneYear
] as const;

export const tenorsBetweenOneAndTwoYears = [Tenor.FifteenMonths, Tenor.EighteenMonths, Tenor.TwentyOneMonths, Tenor.TwoYears] as const;
export const twoYearsTenors = [...oneYearTenors, ...tenorsBetweenOneAndTwoYears] as const;
export type OneYearTenor = (typeof oneYearTenors)[number];
export type TwoYearsTenor = (typeof twoYearsTenors)[number];
export type TenorsBetweenOneAndTwoYears = Record<(typeof tenorsBetweenOneAndTwoYears)[number], number>;

export enum RiskLevelEnum {
  LevelZero = 'no-risk',
  LevelOne = 'stale-spot',
  LevelTwo = 'stale-forward-points',
  LevelThree = 'stale-spot-and-forward-points',
  LevelFour = 'missing-forward-points'
}

export type RateWithEmbodiedRisk = {
  rate: number;
  riskFactor: number;
  riskLevel: RiskLevelEnum;
};

export const isOneYearMarkups = (markups: Markups<any>): markups is Markups<OneYearTenor> => {
  return Object.values(markups).every((markupsTenorValues) => {
    const hasAllOneYearTenors = Object.values(oneYearTenors).every((oneYearTenorValue) => oneYearTenorValue in markupsTenorValues);

    const hasNoTenorsBetweenOneAndTwoYears = Object.values(tenorsBetweenOneAndTwoYears).every(
      (betweenTenorValue) => !(betweenTenorValue in markupsTenorValues)
    );

    return hasAllOneYearTenors && hasNoTenorsBetweenOneAndTwoYears;
  });
};

export const isTwoYearsMarkups = (markups: Markups<any>): markups is Markups<TwoYearsTenor> => {
  return Object.values(markups).every((markupsTenorValues) =>
    Object.values(twoYearsTenors).every((twoYearsTenorsValue) => twoYearsTenorsValue in markupsTenorValues)
  );
};
