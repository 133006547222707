import React, { ButtonHTMLAttributes, MouseEvent } from 'react';
import './TextButton.scss';
import { cn } from '../utils';
import { Link } from 'react-router-dom';

export default function TextButton(props: TextButtonProps) {
  const { onClick, text, link, className, color, withArrow, light, size, underline, disabled = false, arrowDirection = 'right' } = props;

  const buttonContent = (
    <>
      {text}
      {withArrow && <span className="arrow">&nbsp;&#8594;</span>}
    </>
  );

  const buttonProps = {
    className: cn(color, size, 'text-button', {
      disabled,
      light,
      'underline-text': underline,
      reverse: arrowDirection === 'left'
    }),
    onClick
  };

  return (
    <div className={cn(className, 'text-button-container')}>
      {link ? (
        <Link to={link} {...buttonProps}>
          {buttonContent}
        </Link>
      ) : (
        <div {...buttonProps}>{buttonContent}</div>
      )}
    </div>
  );
}

interface TextButtonProps extends ButtonHTMLAttributes<HTMLInputElement> {
  text: string;
  link?: string;
  withArrow?: boolean;
  arrowDirection?: 'right' | 'left';
  color?: 'blue' | 'white' | 'text-primary' | 'light-grey';
  onClick?: (event: MouseEvent<HTMLDivElement | HTMLAnchorElement>) => void;
  disabled?: boolean;
  light?: boolean;
  size?: 'small' | 'regular';
  underline?: boolean;
}
