import { FundTransferType } from '@grain/core-types';
import { useDebounce } from '@grain/web-components/hooks';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { queries } from 'queries';

export const useFundTransfers = (params: UseFundTransfersParams) => {
  const debouncedSearchTerm = useDebounce(params?.searchTerm, 500);

  const { data, isLoading } = useQuery({
    ...queries.fundTransfers.list({
      page: params.page,
      perPage: params.perPage,
      searchTerm: debouncedSearchTerm,
      type: params.type
    }),
    placeholderData: keepPreviousData,
    staleTime: 60 * 1_000
  });

  return {
    fundTransfers: data?.fundTransfers,
    totalFundTransfers: data?.totalFundTransfers,
    isLoadingFundTransfers: isLoading
  };
};

type UseFundTransfersParams = {
  page: number;
  perPage: number;
  type?: FundTransferType;
  searchTerm?: string;
};
