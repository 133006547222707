import React, { useCallback, useEffect, useState } from 'react';
import { Trash } from 'iconsax-react';
import BaseTable, { BaseTableColumnDef } from '@grain/web-components/table/BaseTable';
import TwoButtonsDialog from '@grain/web-components/dialogs/TwoButtonsDialog';
import ForwardPercentageByCountryDialog from './ForwardPercentageByCountryDialog';
import PartnersService from '../../../services/PartnersService';
import { AdminConsoleApiForwardPercentageByCountry } from '@grain/admin-console-api-types';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@grain/web-components/accordion/Accordion';
import { PartnerSectionTitleMarker } from '../partner/PartnerSectionTitleMarker';
import { Card } from '@grain/web-components/layout/Card';

export function PartnerForwardPercentageByCountrySection(props: ForwardPercentageByCountrySectionProps) {
  const { partnerId } = props;
  const [loading, setLoading] = useState(false);
  const [forwardPercentage, setForwardPercentage] = useState<AdminConsoleApiForwardPercentageByCountry[]>([]);

  const loadForwardPercentageList = useCallback(async () => {
    setLoading(true);
    const percentageList = await PartnersService.getInstance().getForwardPercentageCountry(partnerId);
    setForwardPercentage(percentageList.sort((hp1, hp2) => hp1.country.localeCompare(hp2.country)));
    setLoading(false);
  }, [partnerId]);

  useEffect(() => {
    loadForwardPercentageList();
  }, [loadForwardPercentageList]);

  const onDelete = useCallback(
    async (id: string) => {
      await PartnersService.getInstance().deleteForwardPercentageCountry(partnerId, id);
      await loadForwardPercentageList();
    },
    [loadForwardPercentageList, partnerId]
  );

  const columns: BaseTableColumnDef<AdminConsoleApiForwardPercentageByCountry>[] = [
    {
      accessorFn: (forwardCountryPercentage: AdminConsoleApiForwardPercentageByCountry) => forwardCountryPercentage.country,
      id: 'country',
      header: 'Country'
    },
    {
      accessorFn: (forwardCountryPercentage: AdminConsoleApiForwardPercentageByCountry) => `${forwardCountryPercentage.percentage}%`,
      id: 'percentage',
      header: 'Forward Percentage'
    },
    {
      cell: React.useCallback(
        // @ts-expect-error - moving to React 18
        ({ row: { original: forwardPercentage } }) => (
          <ForwardPercentageByCountryDialog
            partnerId={partnerId}
            forwardPercentage={forwardPercentage}
            onForwardPercentageChanged={loadForwardPercentageList}
          />
        ),
        [partnerId, loadForwardPercentageList]
      ),
      id: 'edit',
      header: ''
    },
    {
      cell: React.useCallback(
        // @ts-expect-error - moving to React 18
        ({ row: { original: forwardPercentage } }) => (
          <TwoButtonsDialog
            title="Delete Country Forward Percentage"
            onAccept={() => onDelete(forwardPercentage.id)}
            content={`This will remove ${forwardPercentage.country} forward percentage declaration`}
            ctaQuestion="Are you sure you want to remove it?"
            trigger={<Trash />}
          />
        ),
        [onDelete]
      ),
      id: 'delete',
      header: ''
    }
  ];

  return (
    <Card>
      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>
            <span className="font-medium text-secondary flex items-center gap-2">
              <PartnerSectionTitleMarker color="cta" />
              Forward Percentage By Country
            </span>
          </AccordionTrigger>

          <AccordionContent>
            <BaseTable
              loading={loading}
              columns={columns}
              data={forwardPercentage}
              paginated
              pageSize={5}
              fixedLayout
              paginatorSiblingElement={
                <ForwardPercentageByCountryDialog partnerId={partnerId} onForwardPercentageChanged={loadForwardPercentageList} />
              }
            />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
}

type ForwardPercentageByCountrySectionProps = {
  partnerId: string;
};
