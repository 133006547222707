import React from 'react';
import { RaceBy, Ring } from '@uiball/loaders';
import './Spinner.scss';
import colors from '../styles/colors.scss';
import { cn } from '../utils';

export default function Spinner(props: SpinnerProps) {
  const { color = colors.cta, variant = 'dots' } = props;
  return (
    <div className={cn('spinner-container', variant)}>
      {variant === 'dots' && <Ring color={color} />}
      {variant === 'bar' && <RaceBy color={color} />}
    </div>
  );
}

type SpinnerProps = {
  color?: string;
  variant?: 'dots' | 'bar';
};
