import './PartnerUsersSection.scss';
import React from 'react';
import { AdminConsoleApiPartnerUser, AdminConsoleRole, AdminConsoleUser, DashboardRole } from '@grain/admin-console-api-types';
import PartnerUsersTable from './PartnerUsersTable';
import { Card } from '@grain/web-components/layout/Card';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@grain/web-components/accordion/Accordion';
import { PartnerSectionTitleMarker } from '../partner/PartnerSectionTitleMarker';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../store/userStore';
import InvitePartnerUserDialog from './InvitePartnerUserDialog';
import { IconicButton } from '@grain/web-components/iconic-button/IconicButton';
import { Add } from 'iconsax-react';
import { useDeletePartnerUser, useEditPartnerUser, useInvitePartnerUser, usePartnerUsers } from '../../../hooks';
import { notifySuccess } from '@grain/web-components/notifications/toasters';

export function PartnerUsersSection(props: PartnerUsersSectionProps) {
  const { partnerId } = props;
  const currentUser = useSelector(userSelectors.getCurrentUser) as AdminConsoleUser;
  const { partnerUsers, isLoadingPartnerUsers } = usePartnerUsers(partnerId);
  const { editPartnerUser, isEditingPartnerUser } = useEditPartnerUser();
  const { invitePartnerUser, isInvitingPartnerUser } = useInvitePartnerUser();
  const { deletePartnerUser } = useDeletePartnerUser();

  const onUserEdit = async (id: string, firstName: string, lastName: string, role: DashboardRole, partnerId: string) => {
    await editPartnerUser({ id, firstName, lastName, role, partnerId });
    notifySuccess('The changes have been saved');
  };

  const onUserInvite = async (firstName: string, lastName: string, email: string, role: DashboardRole) => {
    await invitePartnerUser({ firstName, lastName, email, role, partnerId });
    notifySuccess('The invitation has been sent');
  };

  const onUserDelete = async (user: AdminConsoleApiPartnerUser) => {
    await deletePartnerUser({ userId: user.id, partnerId });
    notifySuccess('The user has been deleted');
  };

  return (
    <Card>
      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>
            <span className="font-medium text-secondary flex items-center gap-2">
              <PartnerSectionTitleMarker color="light-blue" />
              Users
            </span>
          </AccordionTrigger>

          <AccordionContent className="relative h-96">
            <PartnerUsersTable
              users={partnerUsers}
              loading={isLoadingPartnerUsers}
              onUserDelete={onUserDelete}
              onUserEdit={onUserEdit}
              isEditingPartnerUser={isEditingPartnerUser}
              isFixedLayout={true}
            />
            {[AdminConsoleRole.SuperAdmin].includes(currentUser?.role) && (
              <InvitePartnerUserDialog
                triggerComponent={<IconicButton icon={Add} text="Invite User" className={'absolute top-0 right-2'} />}
                onInvite={onUserInvite}
                isLoading={isInvitingPartnerUser}
              />
            )}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
}

type PartnerUsersSectionProps = {
  partnerId: string;
};
