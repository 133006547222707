import React, { useEffect, useState } from 'react';
import { ActionRejection } from '@grain/core-types';
import TextButton from '@grain/web-components/buttons/TextButton';
import Page from '../layout/Page';
import SectionCard from '@grain/web-components/layout/SectionCard';
import { Dislike } from 'iconsax-react';
import colors from '@grain/web-components/styles/colors.scss';
import { getOrganizationTypeText, useOrganizationType } from '../organizations/getOrganizationType';
import ActionRejectionsTable from './ActionRejectionsTable';
import ActionRejectionsService from '../../services/ActionRejectionsService';

export default function ActionRejectionsManagementPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [actionRejections, setActionRejections] = useState<ActionRejection[]>();
  const [organizationType, toggleOrganizationType] = useOrganizationType();

  useEffect(() => {
    (async function () {
      setLoading(true);
      try {
        const rejections = await ActionRejectionsService.getInstance().getActionRejectionsRequest({ organizationType });
        setActionRejections(rejections);
      } finally {
        setLoading(false);
      }
    })();
  }, [organizationType]);

  return (
    <Page title="Action Rejections Management" subtitle="Review rejections of Get Quote and Accept Hedge">
      <SectionCard
        title="All Action Rejections"
        icon={<Dislike color={colors.almostBlack} />}
        iconBackgroundColor={colors.yellow}
        loading={loading}
        headerRightComponent={
          <TextButton
            text={`${getOrganizationTypeText(organizationType)} Rejections`}
            color="text-primary"
            size="small"
            onClick={toggleOrganizationType}
          />
        }
      >
        <ActionRejectionsTable actionRejections={actionRejections || []} showCustomerId />
      </SectionCard>
    </Page>
  );
}
