import { LiquidityProvider } from '@grain/core-types';

export enum ForwardBookingError {
  // FYI errors
  LogonError = 'LogonError',
  HeartbeatError = 'HeartbeatError',

  // General errors
  LiquidityProviderUnavailable = 'LiquidityProviderUnavailable',
  Timeout = 'Timeout',
  NoConnection = 'NoConnection',
  NotBusinessDay = 'NotBusinessDay',
  CreditCheckFailure = 'CreditCheckFailure',

  // Quote errors
  QuoteCanceled = 'QuoteCanceled',
  QuoteRejected = 'QuoteRejected',
  MarketDataRequestReject = 'MarketDataRequestReject',

  // Order errors
  OrderExecutionRejection = 'OrderExecutionRejection',
  OrderExecutionCancel = 'OrderExecutionCancel',
  PricingNotAvailable = 'PricingNotAvailable',
  InvalidQuoteId = 'InvalidQuoteId',
  QuoteExpired = 'QuoteExpired',

  // Critical errors
  InternalFixServerError = 'InternalFixServerError',
  Unhandled = 'Unhandled'
}

export const BookForwardRetryErrors: Record<string, ForwardBookingError> = {
  'Asked price is no longer available.': ForwardBookingError.PricingNotAvailable,
  'Quote/Request Expired, used when the request or quote expiry is reached.': ForwardBookingError.QuoteExpired,
  'RequestValidationError.InvalidQuoteID': ForwardBookingError.InvalidQuoteId
};

export type ForwardBookingErrorMetadata = {
  reason?: string;
  externalQuoteId?: string;
  provider?: LiquidityProvider;
};

export interface ForwardBookingErroredPayload {
  id: string;
  providersErrors: Array<{
    error: ForwardBookingError;
    metadata?: ForwardBookingErrorMetadata;
  }>;
}
